var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", [
    _c(
      "div",
      { staticClass: "modal-view" },
      [
        _c("div", { staticClass: "modal-view__header" }, [
          _c(
            "div",
            { staticClass: "modal-view__header__icon" },
            [_vm._t("icon")],
            2
          ),
          _vm._v(" "),
          _c("h2", {
            staticClass: "modal-view__header__text",
            domProps: { innerHTML: _vm._s(_vm.title) }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "modal-view__header__btn",
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 27.15 27.15"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M16.26,13.57,26.59,3.24A1.9,1.9,0,0,0,23.9.56L13.57,10.89,3.24.56A1.88,1.88,0,0,0,.56.56a1.88,1.88,0,0,0,0,2.68L10.89,13.57.56,23.9a1.9,1.9,0,1,0,2.68,2.69L13.57,16.26,23.9,26.59a1.93,1.93,0,0,0,1.35.56,1.91,1.91,0,0,0,1.34-3.25Z"
                    }
                  })
                ]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { ref: "content", staticClass: "modal-view__content" }, [
          _c(
            "div",
            { staticClass: "modal-view__content__wrapper" },
            [_vm._t("default")],
            2
          )
        ]),
        _vm._v(" "),
        _vm._t("footer")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0eaeaf66", { render: render, staticRenderFns: staticRenderFns })
  }
}