<template>
    <button :id="idTag" class="btn" :class="[classes]" :tabindex="tabIndex" :disabled="disabled" @click="$emit('click', $event)">{{ text }}</button>
</template>

<script>
    export default {
        props: {
            type: '',
            text: '',
            tabIndex: '',
            disabled: {type: Boolean, default: false},
            idTag: {type: String, default: ''}
        },
        computed: {
            classes() {
                return (this.type && this.type.length) ? 'btn--' + this.type : '';
            }
        }
    }
</script>