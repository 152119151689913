var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "label-checkbox",
      class: { "label-input--error": _vm.error }
    },
    [
      _c("input", {
        attrs: {
          type: "checkbox",
          id: _vm.uniqueId,
          value: "1",
          disabled: _vm.disabled
        },
        domProps: { checked: _vm.value },
        on: { change: _vm.onChange }
      }),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "label", attrs: { for: _vm.uniqueId } },
        [
          _c("span", { staticClass: "label-checkbox__circle" }),
          _vm._v(" "),
          _c("span", { staticClass: "label-checkbox__check" }),
          _vm._v(" "),
          _c("span", { staticClass: "label-checkbox__box" }),
          _vm._v(" " + _vm._s(_vm.name) + "\n        "),
          _vm._t("default")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2dcbf02b", { render: render, staticRenderFns: staticRenderFns })
  }
}