var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-header__content" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "mobile-menu",
          on: {
            click: function($event) {
              _vm.open = !_vm.open
            }
          }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("span", { staticClass: "mobile-menu__text" }, [_vm._v("Menu")])
        ]
      ),
      _vm._v(" "),
      _c("nav", { staticClass: "main-nav" }, [_vm._t("nav")], 2)
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobile-menu__hamburger" }, [
      _c("span", {
        staticClass:
          "mobile-menu__hamburger__bar mobile-menu__hamburger__bar--top"
      }),
      _vm._v(" "),
      _c("span", {
        staticClass:
          "mobile-menu__hamburger__bar mobile-menu__hamburger__bar--middle"
      }),
      _vm._v(" "),
      _c("span", {
        staticClass:
          "mobile-menu__hamburger__bar mobile-menu__hamburger__bar--bottom"
      })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1a6f466c", { render: render, staticRenderFns: staticRenderFns })
  }
}