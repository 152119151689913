import '@babel/polyfill';

import Vue from 'vue';
import Vuex from 'vuex';
import AppComp from './components/app/app.vue';
import MobileMenu from './components/mobile-menu.vue';
import Tracker from './tracker.js';
import CurrencyFormatter from 'currency-formatter';
import TRANSLATIONS from './lang/da.json';
import './directives.js';

Vue.use(Vuex);

Vue.filter('numberFilter', (value) => {
	value = value.toString().replace(/\./g, ',');
	return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
});

Vue.filter('priceFilter', (value) => {
	return CurrencyFormatter.format(value, {
		symbol: 'kr.',
		decimal: ',',
		thousand: '.',
		precision: 2,
		format: '%v %s' // %s is the symbol and %v is the value
	});
});

const store = new Vuex.Store({
	state: {
		resultData: {},
		inputData: {},
		chepestProduct: null
	},
	mutations: {
		resultData(state, value) {
			state.resultData = Object.assign({}, value);
		},
		inputData(state, value) {
			state.inputData = Object.assign({}, value);
		},
		chepestProduct(state, value) {
			state.chepestProduct = value;
		}
	},
	getters: {
		tripsPerMonth: (state) => {
			return state.inputData.travelsPerMonth;
		},
		tripsPerYear: (state, getters) => {
			return getters.tripsPerMonth * 12;
		},
		travelDaysPerMonth: (state) => {
			return state.inputData.travelsPerMonth / 2;
		},
		tripPerMonthReplace: (state, getters) => (txt) => {
			return txt.replace(/\{trip_count\}/, getters.tripsPerMonth);
		},
		travelDaysPerWeek: (state) => {
			return state.inputData.travelsPerMonth / 4 / 2;
		}
	},
	actions: {
		trackResult({ state, getters }) {
			Tracker.trackEvent({
				event: 'track-event',
				eventCategory: 'pendlertjek erhverv',
				eventAction: 'calculation results',
				eventLabel:
					state.chepestProduct && state.chepestProduct.content
						? state.chepestProduct.content.name.toLowerCase()
						: '',
				eventValue: 0,
				eventNoninteraction: true,
				pendlertjekBusinessCalculationWeeklyCommutingdays: getters.travelDaysPerWeek,
				pendlertjekBusinessCalculationMonths: state.inputData.monthUse,
				pendlertjekBusinessCalculationZones: state.inputData.zones,
				pendlertjekBusinessCalculationDistance: state.inputData.travelKm,
				pendlertjekBusinessCalculationMetro: state.inputData.metro,
				pendlertjekBusinessCalculationHightaxbracket: state.inputData.topTax,
				pendlertjekBusinessCalculationCartype: (state.inputData.car) ? state.inputData.car.name : 'har ingen bil/ønsker ingen bil',
				pendlertjekBusinessCalculationResultPriceLowest:
					state.chepestProduct && state.chepestProduct.content
						? state.chepestProduct.content.name.toLowerCase()
						: ''
			});
		}
	}
});

window.TRANSLATIONS = TRANSLATIONS;
window.VUEEVENTS = new Vue();

import('./../sass/output/fonts.scss').then((data) => {
	window.VUEEVENTS.$emit('fonts-loaded');
});

import('./../sass/output/result-area.scss').then((data) => {
	window.VUEEVENTS.$emit('resultareaready');
});

const app = new Vue({
	el: '#app',
	store,
	mounted() {
		import('headroom.js').then(({ default: Headroom }) => {
			let header = document.getElementById('main-header');
			let headroom = new Headroom(header);
			headroom.init();
		});
	},
	data() {
		return {
			language: LOCALE
		};
	},
	components: {
		app: AppComp,
		MobileMenu
	}
});
