<template>
        <div class="main-header__content">
            <slot></slot>
            <div class="mobile-menu" @click="open = !open">
                <div class="mobile-menu__hamburger">
                    <span class="mobile-menu__hamburger__bar mobile-menu__hamburger__bar--top"></span>
                    <span class="mobile-menu__hamburger__bar mobile-menu__hamburger__bar--middle"></span>
                    <span class="mobile-menu__hamburger__bar mobile-menu__hamburger__bar--bottom"></span>
                </div>
                <span class="mobile-menu__text">Menu</span>
            </div>
            <nav class="main-nav">
                <slot name="nav"></slot>
            </nav>
        </div>
</template>

<script>
export default {
    data() {
        return {
            open: false
        }
    },
    watch: {
        open() {
            if(this.open) {
                document.getElementsByTagName('body')[0].className += ' showMenu';
            } else {
                document.getElementsByTagName('body')[0].className = document.getElementsByTagName('body')[0].className.replace(/showMenu/, '');
            }
        }
    }
}
</script>
