var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drop-down" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.intSelectedVal,
            expression: "intSelectedVal"
          }
        ],
        staticClass: "drop-down__select",
        attrs: { id: _vm.name, name: _vm.name, tabindex: _vm.tabIndex },
        on: {
          change: function($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function(o) {
                return o.selected
              })
              .map(function(o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.intSelectedVal = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          }
        }
      },
      _vm._l(_vm.list, function(value, item) {
        return _c("option", { domProps: { value: item } }, [
          _vm._v(_vm._s(typeof value == "object" ? value.name : value))
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("label", { staticClass: "drop-down__label", attrs: { for: _vm.name } }, [
      _vm._v(_vm._s(_vm.label) + " ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-41a2fbd1", { render: render, staticRenderFns: staticRenderFns })
  }
}