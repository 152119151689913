<template>
        <div class="app-body" @click="appClick">
            <input-area :result-area-ready="resultAreaReady"></input-area>
            <result-area v-if="hasResults" ref="results"></result-area>
        </div>
</template>

<script>
    import InputArea from './../inputarea/inputarea.vue';
    import Scroll from 'scroll-js';

    export default {
        data() {
          return {
              hasResults: false,
              scroll: null,
              resultAreaReady: false
          }
        },
        mounted() {
            this.scroll = new Scroll(document.documentElement.scrollTop > 0 ? document.documentElement : document.body);
            window.VUEEVENTS.$on('resultareaready', () => {
                this.resultAreaReady = true;
            });

            window.VUEEVENTS.$on('calcresult', (data) => {
                if(data) {
                    this.hasResults = true;
                    this.$store.commit('resultData', data);
                    window.VUEEVENTS.$emit('reset');

                    var self = this;
                    this.$nextTick(() => {
                        setTimeout(function() {
                            self.scroll.toElement(self.$refs.results.$el, {duration: 500});
                            self.$store.dispatch("trackResult");
                        }, 500);
                    });
                }  else {
                    this.hasResults = false;
                }
            });

            window.VUEEVENTS.$on('calcinput', (inputData) => {
                this.$store.commit('inputData', inputData);
            });
        },
        methods: {
            appClick(evt) {
               window.VUEEVENTS.$emit('appclick', evt);
            }
        },
        components: {
            InputArea,
            ResultArea: () => import('./../resultarea/resultarea.vue')
        },
    }
</script>