var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-area", class: { working: _vm.working } },
    [
      _c("div", { staticClass: "input-wrapper" }, [
        _c("div", { staticClass: "intro-text" }, [
          _c("h1", {
            staticClass: "intro-text__heading",
            domProps: { innerHTML: _vm._s(_vm.introHeading) }
          }),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "intro-text__description" },
            [
              _c("span", { domProps: { innerHTML: _vm._s(_vm.description) } }),
              _vm._v(" "),
              _c("info-button", {
                on: {
                  show: function($event) {
                    return _vm.showInfoBox("rejseplan")
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("p", {
            staticClass: "intro-text__description",
            domProps: { innerHTML: _vm._s(_vm.erhverskortNotice) }
          }),
          _vm._v(" "),
          _c("p", {
            staticClass: "intro-text__description",
            domProps: { innerHTML: _vm._s(_vm.pendlerNotice) }
          }),
          _vm._v(" "),
          _c("p", {
            staticClass: "intro-text__description",
            domProps: { innerHTML: _vm._s(_vm.youngNotice) }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "input-fields" },
          [
            _c("dropdown", {
              attrs: {
                id: "days_week_input",
                label: _vm.daysWeekInput,
                name: "daysWeekInput",
                "tab-index": "1",
                list: _vm.daysPerWeek
              },
              model: {
                value: _vm.daysPerWeekValue,
                callback: function($$v) {
                  _vm.daysPerWeekValue = $$v
                },
                expression: "daysPerWeekValue"
              }
            }),
            _vm._v(" "),
            _c("dropdown", {
              attrs: {
                id: "months_pr_year",
                label: _vm.monthInput,
                name: "monthInput",
                "tab-index": "2",
                list: _vm.monthList
              },
              model: {
                value: _vm.monthPerYearValue,
                callback: function($$v) {
                  _vm.monthPerYearValue = $$v
                },
                expression: "monthPerYearValue"
              }
            }),
            _vm._v(" "),
            _c("dropdown", {
              attrs: {
                id: "zones",
                label: _vm.zonesInput,
                name: "zonesInput",
                "tab-index": "3",
                list: _vm.zones
              },
              model: {
                value: _vm.zonesValue,
                callback: function($$v) {
                  _vm.zonesValue = $$v
                },
                expression: "zonesValue"
              }
            }),
            _vm._v(" "),
            _c("dropdown", {
              attrs: {
                id: "distance_input",
                label: _vm.distanceInput,
                name: "distanceInput",
                "tab-index": "4",
                list: _vm.distanceFromHomeList
              },
              model: {
                value: _vm.distanceFromHomeValue,
                callback: function($$v) {
                  _vm.distanceFromHomeValue = $$v
                },
                expression: "distanceFromHomeValue"
              }
            }),
            _vm._v(" "),
            _c("dropdown", {
              attrs: {
                id: "car_type_input",
                label: _vm.carTypeInput,
                name: "carTypeInput",
                "tab-index": "6",
                list: _vm.carlist
              },
              model: {
                value: _vm.carTypeValue,
                callback: function($$v) {
                  _vm.carTypeValue = $$v
                },
                expression: "carTypeValue"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "two-rows" }, [
              _c(
                "div",
                { staticClass: "checkbox-wrapper" },
                [
                  _c("checkbox", {
                    staticClass: "label-checkbox--dark-ui",
                    attrs: { name: _vm.metroInput, value: _vm.metroValue },
                    on: {
                      checked: function(checked) {
                        return (_vm.metroValue = checked)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("info-button", {
                    attrs: {
                      open: _vm.shownInfoBox == "metro",
                      text: _vm.metroHelp
                    },
                    on: {
                      show: function($event) {
                        return _vm.showInfoBox("metro")
                      },
                      close: function() {
                        return (_vm.shownInfoBox = "")
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "checkbox-wrapper" },
                [
                  _c("checkbox", {
                    staticClass: "label-checkbox--dark-ui",
                    attrs: { name: _vm.topTaxInput, value: _vm.topTaxValue },
                    on: {
                      checked: function(checked) {
                        return (_vm.topTaxValue = checked)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("info-button", {
                    attrs: {
                      open: _vm.shownInfoBox == "tax",
                      text: _vm.topTaxHelp
                    },
                    on: {
                      show: function($event) {
                        return _vm.showInfoBox("tax")
                      },
                      close: function() {
                        return (_vm.shownInfoBox = "")
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "calc-block" },
                [
                  _c("btn", {
                    class: { "btn--calculating": _vm.working },
                    attrs: {
                      "id-tag": "btn_calculation",
                      disabled: !_vm.readyToCalc,
                      "tab-index": "8",
                      text: _vm.calcBtn
                    },
                    on: { click: _vm.calculate }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("p", {
              staticClass: "calc-info",
              domProps: { innerHTML: _vm._s(_vm.calcInfo) }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.shownInfoBox == "rejseplan"
        ? _c(
            "modal",
            {
              attrs: {
                closeOnClick: true,
                "is-open": true,
                title: _vm.infoTitle
              },
              on: {
                close: function() {
                  return (_vm.shownInfoBox = "")
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  slot: "icon",
                  src: require("./../../../img/icon-info-help-modal.svg"),
                  alt: ""
                },
                slot: "icon"
              }),
              _vm._v(" "),
              _c("p", { domProps: { innerHTML: _vm._s(_vm.rejseplanHelp) } })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8bbd7d82", { render: render, staticRenderFns: staticRenderFns })
  }
}