<template>
    <div class="label-checkbox" :class="{ 'label-input--error': error}">
        <input type="checkbox" :id="uniqueId" :checked="value" value="1" @change="onChange" :disabled="disabled" />
        <label :for="uniqueId" class="label">
            <span class="label-checkbox__circle"></span>
            <span class="label-checkbox__check"></span>
            <span class="label-checkbox__box"></span> {{ name }}
            <slot></slot>
        </label>
    </div>
</template>

<script type="text/javascript">


    export default{
        props: {
            error: false,
            value: {type: Boolean, default: false},
            name: {type: String, default: ''},
            disabled: {type: Boolean, default: false}
        },
        methods: {
            onChange(evt) {
                if(evt.target.checked) {
                    this.$emit('checked', true);
                } else {
                    this.$emit('checked', false);
                }
            }
        },
        computed: {
            uniqueId() {
                let id = Math.random().toString(36).substring(7);
                return this.name.replace(/\s/g, '_') + id;
            }
        }
    }
</script>