var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "info-button",
      on: {
        click: function($event) {
          return _vm.$emit("show")
        }
      }
    },
    [
      _c(
        "svg",
        {
          staticStyle: { "enable-background": "new 0 0 45.2 45.2" },
          attrs: {
            version: "1.1",
            id: "Layer_1",
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns:xlink": "http://www.w3.org/1999/xlink",
            x: "0px",
            y: "0px",
            viewBox: "0 0 45.2 45.2",
            "xml:space": "preserve"
          }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M34.6,35.9h-6.3v-16c0-1-0.5-1.4-1.4-1.4H11.3c-0.9,0-1.4,0.5-1.4,1.4v1.9c0,1,0.5,1.4,1.4,1.4h6.3v12.7h-7 c-0.9,0-1.4,0.5-1.4,1.4V39c0,1,0.5,1.4,1.4,1.4h23.9c0.9,0,1.4-0.5,1.4-1.4v-1.9C36,36.1,35.5,35.9,34.6,35.9z"
            }
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d:
                "M21.9,14.8h0.6c3,0,5.8-0.8,5.8-4.9V8.2c0-4.3-2.8-4.8-5.8-4.8h-0.6c-3,0-5.8,0.5-5.8,4.8v1.6 C16.2,14.2,18.9,14.8,21.9,14.8z"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open"
            }
          ],
          ref: "infobox",
          staticClass: "info-button__content"
        },
        [
          _c("span", {
            staticClass: "info-button__content__text",
            domProps: { innerHTML: _vm._s(_vm.text) }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4deaa1f9", { render: render, staticRenderFns: staticRenderFns })
  }
}