var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "btn",
      class: [_vm.classes],
      attrs: { id: _vm.idTag, tabindex: _vm.tabIndex, disabled: _vm.disabled },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [_vm._v(_vm._s(_vm.text))]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3ab8cd62", { render: render, staticRenderFns: staticRenderFns })
  }
}