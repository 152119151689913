<template>
  <button class="info-button" @click="$emit('show')">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 45.2 45.2"
      style="enable-background: new 0 0 45.2 45.2"
      xml:space="preserve"
    >
      <path
        d="M34.6,35.9h-6.3v-16c0-1-0.5-1.4-1.4-1.4H11.3c-0.9,0-1.4,0.5-1.4,1.4v1.9c0,1,0.5,1.4,1.4,1.4h6.3v12.7h-7 c-0.9,0-1.4,0.5-1.4,1.4V39c0,1,0.5,1.4,1.4,1.4h23.9c0.9,0,1.4-0.5,1.4-1.4v-1.9C36,36.1,35.5,35.9,34.6,35.9z"
      />
      <path
        d="M21.9,14.8h0.6c3,0,5.8-0.8,5.8-4.9V8.2c0-4.3-2.8-4.8-5.8-4.8h-0.6c-3,0-5.8,0.5-5.8,4.8v1.6 C16.2,14.2,18.9,14.8,21.9,14.8z"
      />
    </svg>
    <span class="info-button__content" v-show="open" ref="infobox">
      <span class="info-button__content__text" v-html="text"></span>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    open: { type: Boolean, default: false },
    text: { type: String, default: "" },
  },
  watch: {
    open(open) {
      if (open) {
        this.enableAppClick = false;
        setTimeout(() => {
          this.enableAppClick = true;
        }, 150);
      }
    },
  },
  data() {
    return {
      enableAppClick: false,
    };
  },
  mounted() {
    window.VUEEVENTS.$on("appclick", (event) => {
      this.$nextTick(() => {
        if (
          this.enableAppClick &&
          this.$refs.infobox &&
          this.open &&
          this.$refs.infobox != event.target &&
          !this.$refs.infobox.contains(event.target)
        ) {
          this.$emit("close");
        }
      });
    });
  },
};
</script>