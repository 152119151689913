<template>
    <div class="drop-down">
        <select class="drop-down__select" :id="name" :name="name" v-model="intSelectedVal" :tabindex="tabIndex">
            <option v-for="(value, item) in list" :value="item">{{ (typeof value == 'object') ? value.name : value }}</option>
        </select>
        <label class="drop-down__label" v-bind:for="name">{{ label}} </label>
    </div>
</template>

<script>
    export default {
        props: {
            label: '',
            list: {type: Array|Object, default() {return [ ]}},
            name: '',
            value: '',
            tabIndex: ''
        },
        data() {
            return {
                intSelectedVal: ''
            }
        },
        mounted() {
            this.intSelectedVal = this.value;
        },
        watch: {
            intSelectedVal() {
                this.$emit('input', this.intSelectedVal)
            },
            value() {
                if(this.value !== this.intSelectedVal) {
                    this.intSelectedVal = this.value;
                }
            }
        }
    }
</script>