<template>
  <div class="input-area" :class="{ working: working }">
    <div class="input-wrapper">
      <div class="intro-text">
        <h1 class="intro-text__heading" v-html="introHeading"></h1>
        <p class="intro-text__description">
          <span v-html="description"></span>
          <info-button @show="showInfoBox('rejseplan')"></info-button>
        </p>
        <p class="intro-text__description" v-html="erhverskortNotice"></p>
        <p class="intro-text__description" v-html="pendlerNotice"></p>
        <p class="intro-text__description" v-html="youngNotice"></p>
      </div>
      <div class="input-fields">
        <dropdown
          id="days_week_input"
          :label="daysWeekInput"
          name="daysWeekInput"
          tab-index="1"
          :list="daysPerWeek"
          v-model="daysPerWeekValue"
        ></dropdown>
        <dropdown
          id="months_pr_year"
          :label="monthInput"
          name="monthInput"
          tab-index="2"
          :list="monthList"
          v-model="monthPerYearValue"
        ></dropdown>
        <dropdown
          id="zones"
          :label="zonesInput"
          name="zonesInput"
          tab-index="3"
          :list="zones"
          v-model="zonesValue"
        ></dropdown>
        <dropdown
          id="distance_input"
          :label="distanceInput"
          name="distanceInput"
          tab-index="4"
          :list="distanceFromHomeList"
          v-model="distanceFromHomeValue"
        ></dropdown>
        <dropdown
          id="car_type_input"
          :label="carTypeInput"
          name="carTypeInput"
          tab-index="6"
          :list="carlist"
          v-model="carTypeValue"
        ></dropdown>

        <div class="two-rows">
          <div class="checkbox-wrapper">
            <checkbox
              class="label-checkbox--dark-ui"
              :name="metroInput"
              :value="metroValue"
              @checked="(checked) => (metroValue = checked)"
            ></checkbox>
            <info-button
              @show="showInfoBox('metro')"
              :open="shownInfoBox == 'metro'"
              :text="metroHelp"
              @close="() => (shownInfoBox = '')"
            ></info-button>
          </div>
          <div class="checkbox-wrapper">
            <checkbox
              class="label-checkbox--dark-ui"
              :name="topTaxInput"
              :value="topTaxValue"
              @checked="(checked) => (topTaxValue = checked)"
            ></checkbox>
            <info-button
              @show="showInfoBox('tax')"
              :open="shownInfoBox == 'tax'"
              :text="topTaxHelp"
              @close="() => (shownInfoBox = '')"
            ></info-button>
          </div>

          <div class="calc-block">
            <btn
              id-tag="btn_calculation"
              :class="{ 'btn--calculating': working }"
              :disabled="!readyToCalc"
              tab-index="8"
              :text="calcBtn"
              @click="calculate"
            ></btn>
          </div>
        </div>
        <p v-html="calcInfo" class="calc-info"></p>
      </div>
    </div>

    <modal
      v-if="shownInfoBox == 'rejseplan'"
      :closeOnClick="true"
      :is-open="true"
      :title="infoTitle"
      @close="() => (shownInfoBox = '')"
    >
      <img src="./../../../img/icon-info-help-modal.svg" alt slot="icon" />
      <p v-html="rejseplanHelp"></p>
    </modal>
  </div>
</template>

<script>
import DropDown from "../drop-down/drop-down.vue";
import Button from "../button/button.vue";
import Tracker from "./../../tracker.js";
import Checkbox from "./../input-field/checkbox.vue";
import InfoButton from "./../input-field/info-button.vue";
import Modal from "./../modal.vue";

export default {
  props: {
    resultAreaReady: { type: Boolean, default: false },
  },
  data() {
    return {
      introHeading: TRANSLATIONS["input-header"]["intro-heading"],
      description: TRANSLATIONS["input-header"]["description"],
      erhverskortNotice: TRANSLATIONS["input-header"]["erhverskort-notice"],
      pendlerNotice: TRANSLATIONS["input-header"]["pendler-notice"],
      youngNotice: TRANSLATIONS["input-header"]["young-notice"],
      calcBtn: TRANSLATIONS["input-header"]["calc-btn"],
      calcInfo: TRANSLATIONS["input-header"]["calc-info"],
      topTaxHelp: TRANSLATIONS["input-header"]["toptax-help"],
      metroHelp: TRANSLATIONS["input-header"]["metro-help"],
      infoTitle: TRANSLATIONS["input-header"]["info-title"],
      rejseplanHelp: TRANSLATIONS["input-header"]["rejseplan-help"],

      daysWeekInput: TRANSLATIONS["input-header"]["days-week-input"],
      monthInput: TRANSLATIONS["input-header"]["month-input"],
      zonesInput: TRANSLATIONS["input-header"]["zones-input"],
      distanceInput: TRANSLATIONS["input-header"]["distance-input"],
      daysPerWeek: TRANSLATIONS["input-header"]["days-week-options"],
      metroInput: TRANSLATIONS["input-header"]["metro-input"],
      topTaxInput: TRANSLATIONS["input-header"]["toptax-input"],
      carTypeInput: TRANSLATIONS["input-header"]["cartype-input"],

      daysPerWeekValue: 5,
      monthPerYearValue: 11,
      zonesValue: 0,
      distanceFromHomeValue: 0,
      metroValue: false,
      topTaxValue: false,
      carTypeValue: 0,

      working: false,
      zones: [],
      carlist: [],

      shownInfoBox: "",

      readyToCalc: false,
    };
  },
  mounted() {
    import("./../../data/prices/prices.mjs").then(({ default: prices }) => {
      this.zones = Object.keys(prices);
      this.readyToCalc = true;
    });

    import("./../../data/prices/cars.mjs").then(({ default: cars }) => {
      cars.push({
        name: TRANSLATIONS["input-header"]["no-car-option"],
      });

      this.carlist = cars;
    });
  },
  watch: {
    daysPerWeekValue() {
      Tracker.trackEvent(
        Object.assign(
          {},
          {
            event: "track-event",
            eventCategory: "pendlertjek erhverv",
            eventAction: "onchange",
            eventLabel: "quantity dates",
            eventValue: 0,
            eventNoninteraction: true,
          },
          this.trackingData
        )
      );
    },
    monthPerYearValue() {
      Tracker.trackEvent(
        Object.assign(
          {},
          {
            event: "track-event",
            eventCategory: "pendlertjek erhverv",
            eventAction: "onchange",
            eventLabel: "quantity months",
            eventValue: 0,
            eventNoninteraction: true,
          },
          this.trackingData
        )
      );
    },
    zonesValue() {
      Tracker.trackEvent(
        Object.assign(
          {},
          {
            event: "track-event",
            eventCategory: "pendlertjek erhverv",
            eventAction: "onchange",
            eventLabel: "quantity zones",
            eventValue: 0,
            eventNoninteraction: true,
          },
          this.trackingData
        )
      );
    },
    distanceFromHomeValue() {
      Tracker.trackEvent(
        Object.assign(
          {},
          {
            event: "track-event",
            eventCategory: "pendlertjek erhverv",
            eventAction: "onchange",
            eventLabel: "distance to home",
            eventValue: 0,
            eventNoninteraction: true,
          },
          this.trackingData
        )
      );
    },
    carTypeValue() {
      Tracker.trackEvent(
        Object.assign(
          {},
          {
            event: "track-event",
            eventCategory: "pendlertjek erhverv",
            eventAction: "onchange",
            eventLabel: "cartype",
            eventValue: 0,
            eventNoninteraction: true,
          },
          this.trackingData
        )
      );
    },
    metroValue() {
      Tracker.trackEvent(
        Object.assign(
          {},
          {
            event: "track-event",
            eventCategory: "pendlertjek erhverv",
            eventAction: "onchange",
            eventLabel: "metro",
            eventValue: 0,
            eventNoninteraction: true,
          },
          this.trackingData
        )
      );
    },
    topTaxValue() {
      Tracker.trackEvent(
        Object.assign(
          {},
          {
            event: "track-event",
            eventCategory: "pendlertjek erhverv",
            eventAction: "onchange",
            eventLabel: "high tax bracket",
            eventValue: 0,
            eventNoninteraction: true,
          },
          this.trackingData
        )
      );
    },
  },
  methods: {
    showInfoBox(name) {
      this.shownInfoBox = name;
    },
    calculate() {
      if (this.readyToCalc) {
        window.VUEEVENTS.$emit("showmsg", {
          messages: { 0: TRANSLATIONS["messages"]["calculating"] },
          type: "info",
        });

        let travelKm = this.travelKm;

        Tracker.trackEvent(
          Object.assign(
            {},
            {
              event: "track-event",
              eventCategory: "pendlertjek erhverv",
              eventAction: "calculation start",
              eventLabel: "",
              eventValue: 0,
              eventNoninteraction: true,
            },
            this.trackingData
          )
        );

        import("./../../services/companycalculator.mjs")
          .then(({ default: CompanyCalculator }) => {
            let caluclator = new CompanyCalculator(this.calcInput);

            caluclator.loadData().then(() => {
              window.VUEEVENTS.$emit("calcresult", {
                pendlerkort: caluclator.pendlerkortPrice(),
                pendler20: caluclator.pendler20(),
                erhverskort: caluclator.erhverskort({ travelKm }),
                rejsekort: caluclator.rejsekort(),
                travelsPerYear: caluclator.getTravlesPrYear(),
                car: caluclator.car({ travelKm }),
              });

              window.VUEEVENTS.$emit("calcinput", this.calcInput);
              window.VUEEVENTS.$emit("showmsg", {
                messages: { 0: TRANSLATIONS["messages"]["success"] },
                type: "success",
              });
            });
          })
          .catch((err) => {
            console.error(err);
            Tracker.trackEvent(
              Object.assign(
                {},
                {
                  event: "track-event",
                  eventCategory: "pendlertjek erhverv",
                  eventAction: "calculation error",
                  eventLabel: "",
                  eventValue: 0,
                  eventNoninteraction: true,
                },
                this.trackingData
              )
            );
          });
      }
    },
  },
  computed: {
    calcInput() {
      let input = {
        travelsPerMonth: this.travelsPerMonth,
        monthUse: this.monthUse,
        zones: this.selectedZones,
        metro: this.metroValue,
        topTax: this.topTaxValue,
        travelKm: this.travelKm,
      };

      let car = this.carlist[this.carTypeValue];
      if (Object.keys(car).length >= 5) {
        input.car = car;
      }

      return input;
    },
    trackingData() {
      return {
        pendlertjekBusinessCalculationWeeklyCommutingdays:
          this.calcInput.travelsPerMonth / 4 / 2,
        pendlertjekBusinessCalculationMonths: this.calcInput.monthUse,
        pendlertjekBusinessCalculationZones: this.calcInput.zones,
        pendlertjekBusinessCalculationDistance: this.calcInput.travelKm,
        pendlertjekBusinessCalculationMetro: this.calcInput.metro,
        pendlertjekBusinessCalculationCartype: (this.calcInput.car) ? this.calcInput.car.name : 'har ingen bil/ønsker ingen bil',
        pendlertjekBusinessCalculationHightaxbracket: this.calcInput.topTax,
      };
    },
    monthList() {
      let months = [];
      for (let i = 1; i <= 12; i++) {
        months.push(i);
      }

      return months;
    },
    distanceFromHomeList() {
      let distances = [
        {
          name: "1 til 12 km",
          value: 5,
        },
      ];

      for (let i = 12; i <= 50; i++) {
        distances.push({
          name: i.toString() + " km",
          value: i,
        });
      }

      return distances;
    },
    travelsPerMonth() {
      return this.daysPerWeekValue * 4 * 2;
    },
    selectedZones() {
      return parseInt(this.zones[this.zonesValue]);
    },
    monthUse() {
      return this.monthPerYearValue + 1;
    },
    travelKm() {
      return this.distanceFromHomeList[this.distanceFromHomeValue].value;
    },
  },
  components: {
    dropdown: DropDown,
    btn: Button,
    Checkbox,
    InfoButton,
    Modal,
  },
};
</script>